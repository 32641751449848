/**
* Generated automatically at built-time (2025-04-01T08:07:36.698Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "tilbud-utekjokken",templateKey: "sites/100-7a86615a-87b7-4c5b-9c6d-5e6e2597855e"};