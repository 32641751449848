/**
* Generated automatically at built-time (2025-04-01T08:07:36.416Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "bygge-utekjokken",templateKey: "sites/100-7bde93e0-bca8-47bf-8c90-8820e1fe6776"};